
.page-map {
    .map-loading {
        position: absolute;
        top: 0;
        left: 400px;
        bottom: 0;
        right: 0;
        overflow: hidden;
        transition: background-color .3s linear;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;

        color: white;

        > * {
            display: none;
        }


        &.loading {
            background-color: rgba(0,0,0,0.5);

            > * {
                display: flex;
            }
        }

        &.hide {
            display: none;
        }
    }
}

@media only screen and (max-width: 991px) {
    .page-map .map-loading {
        left: 300px;
    }
}

@media only screen and (max-width: 767px) {
    .page-map .map-loading { 
        left: 0;
    }
}