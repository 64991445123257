
.component-overlay .language-picker {
    padding: 20px 80px 20px 20px;
    color: #fff;
    font-size: 20px;
    margin-top: 30px;
    font-family: Merriweather, serif;

    span {
        margin: 0 20px;
    }

    a {
        color: rgba(255,255,255,0.5);
        text-decoration: none;

        &.selected {
            color: #fff;
        }
    }
}

.component-overlay .list-links a {
    color: #fff;
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 479px) {
    .component-overlay .language-picker { 
        padding-right: 20px;
        font-size: 14px;
        margin-top: 0;
    }
}